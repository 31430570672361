<div fxLayout="column" fusePerfectScrollbar class="confirm-dialog-container">
  <mat-toolbar class="mat-toolbar mat-accent">
    <h2 mat-dialog-title [innerHTML]="titleKey | translate: titleKeyParams"></h2>
  </mat-toolbar>
  <mat-dialog-content *ngIf="!showTermsToAcceptLayout">
    <p [innerHTML]="messageKey | translate: messageKeyParams"></p>
    <p *ngIf="showConfirmMsg" [innerHTML]="actionKey | translate: actionKeyParams"></p>
    <p *ngIf="!!confirmExtraText" [innerHTML]="confirmExtraText"></p>
    <br />

    <form [formGroup]="confirmForm">
      <mat-form-field *ngIf="withReplyMessage" appearance="outline" fxFlex="100">
        <mat-label>Message</mat-label>
        <input matInput formControlName="message" />
      </mat-form-field>

      <div class="terms" fxLayout="column" fxLayoutAlign="left top" *ngIf="showTerms">
        <mat-checkbox
          data-test="confirm-dialog-checkbox"
          *ngIf="mustAcceptTerms"
          name="accept"
          (change)="changeAcceptTerms($event)">
          <span *ngIf="!mustAcceptTerms">
            {{ 'confirmDialog.documentsIncludedText' | translate }}
          </span>
          <span *ngIf="mustAcceptTerms && companySpecificTerms === ''">
            {{ 'confirmDialog.documentsReviewedText' | translate }}
          </span>
          <span *ngIf="mustAcceptTerms && companySpecificTerms !== ''">
            {{
              'confirmDialog.documentsReviewedText.companySpecific.' + companySpecificTerms
                | translate
            }}
          </span>
        </mat-checkbox>

        <div fxLayout="column" class="file-names">
          <div *ngFor="let doc of additionalDocumentsToAccept" class="termsText">
            <a target="_blank" href="{{ doc.url }}">{{ doc.name }}</a>
          </div>
          <div *ngFor="let attachment of uploadedDocumentsToAccept" class="termsText">
            <a (click)="openPreview($event, attachment)" href="#">
              {{ attachment.originalFilename }}
            </a>
          </div>
        </div>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-content>
    <div class="content p-24" *ngIf="showTermsToAcceptLayout">
      <div fxLayout="row wrap" class="terms" fxLayoutAlign="center">
        <h2 class="title" fxFlex="100">
          {{ 'terms.note' | translate: { serviceName: serviceName } }}
        </h2>

        <div class="item" fxFlex="100">
          <div class="item-title" [innerHtml]="'terms.htmlTitle1' | translate"></div>
          <div
            class="item-description"
            [innerHtml]="'terms.htmlText1' | translate"
            [@animate]="{ value: '*', params: { delay: '150ms', y: '25px' } }"></div>
        </div>

        <div class="item" fxFlex="100">
          <div class="item-title" [innerHtml]="'terms.htmlTitle2' | translate"></div>
          <div
            class="item-description"
            [innerHtml]="'terms.htmlText2' | translate"
            [@animate]="{ value: '*', params: { delay: '200ms', y: '25px' } }"></div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions *ngIf="showTermsToAcceptLayout">
    <button mat-raised-button color="accent" (click)="closeTerms()" fxAlignLayout="center">
      Close Terms & Conditions
    </button>
  </mat-dialog-actions>
  <mat-dialog-actions *ngIf="!showTermsToAcceptLayout">
    <button
      *ngIf="showCancelBtn"
      mat-raised-button
      color="secondary"
      (click)="onCloseCancel()"
      data-test="dialog-cancel">
      {{ cancelBtnKey | translate }}
    </button>
    <button
      *ngIf="showTermsToAccept"
      [disabled]="!termsAccepted"
      mat-raised-button
      color="primary"
      data-test="dialog-confirm"
      (click)="onCloseConfirm()">
      {{ confirmBtnKey | translate }}
    </button>
    <button
      *ngIf="showConfirmBtn && !showTermsToAccept"
      mat-raised-button
      color="primary"
      (click)="onCloseConfirm()"
      data-test="dialog-confirm">
      {{ confirmBtnKey | translate }}
    </button>
  </mat-dialog-actions>
</div>
