import { Injectable } from '@angular/core';
import { ToasterService } from '@app/core/services/toaster.service';
import { LocalStorageService } from '@app/core/services/local-storage.service';
import { CurrentUserService } from '@app/core/services/current-user.service';
import { ApiService } from '@app/core/services/api.service';

import { User } from '@app/core/types/user';
import { Login, LoginResponse } from '@app/core/types/auth';
import { Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { catchError, map, tap } from 'rxjs/operators';
import { UtilsHelper } from '@app/shared/helpers/utils';
import { UnsavedChangesService } from '@app/core/services/unsavedChanges.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(
    private router: Router,
    private localStorageService: LocalStorageService,
    private currentUserService: CurrentUserService,
    private toasterService: ToasterService,
    private _unsavedChangesService: UnsavedChangesService,
    private apiService: ApiService,
  ) {}

  refreshLoggedInUser(): Observable<User | null> {
    if (!this.isAuthenticated()) return of(null);
    return this.apiService.getRequestingUserInfo().pipe(
      map((user) => {
        if (user.role == 'root-admin') user.root = true;
        user.lastActivityUpdatedAt = new Date();
        return user;
      }),
      tap((user) => {
        this.currentUserService.setUser(user);
        this.currentUserService.setLoggedIn(true);
      }),
      catchError((err) => {
        console.error(err);
        this.signOut();
        return of(null);
      }),
    );
  }

  /*
    Updates user activity to BE to refresh the jwt token expiry.
   */
  updateUserActivity(): void {
    if (!this.isAuthenticated()) {
      return;
    }
    this.apiService
      .updateUserActivity()
      .pipe(
        tap((response) => {
          if (response) {
            this.localStorageService.setLoggedAccount(response.token, response.expiresAt);
          }
        }),
        catchError((err) => {
          console.error(err);
          return of(null);
        }),
      )
      .subscribe();
  }

  async signIn(credentials: Login): Promise<User> {
    const response = await this.apiService.login(credentials).toPromise();

    if (!response || !response.token || !response.user) {
      throw new Error('Invalid auth response');
    }

    let loggedUser = response.user;
    if (loggedUser.role == 'root-admin') loggedUser.root = true;

    if (response.isFirstLogin) {
      console.log('First login for user');
    }
    this.currentUserService.setFirstLogin(response.isFirstLogin);
    this.localStorageService.setLoggedAccount(response.token, response.expiresAt);
    this.currentUserService.setUser(loggedUser);
    this.currentUserService.setLoggedIn(true);

    return loggedUser;
  }

  signOut() {
    try {
      this.removeLoggedUser();
      if (UtilsHelper.isExternalPage() === false) {
        this._unsavedChangesService.hasUnsavedChanges.next(false);
        /** If we are not on external pages, forward to login **/
        window.location.replace('/auth/login');
      }
    } catch (err) {
      console.warn(err);
      this.toasterService.showToaster('login.failedLogout', { type: 'error' });
    }
  }

  removeLoggedUser(): void {
    this.currentUserService.remove();
    this.localStorageService.removeLoggedAccount();
  }

  public isAuthenticated(): boolean {
    const currentDate: Date = new Date();
    const userData = this.localStorageService.getCurrentUser();

    if (userData && userData.token && userData.expiresAt) {
      const expirationDate: Date = new Date(userData.expiresAt);
      // Check token expiration
      if (currentDate.getTime() > expirationDate.getTime()) {
        this.removeLoggedUser();
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  async register(registration: any) {
    const response = await this.apiService.register(registration).toPromise();

    if (!response || !response.token || !response.user) {
      throw new Error('Invalid register response');
    }

    //-- not sure if this is used by the register page and if so, should these be set.
    if (!registration.quickRegister) {
      this.localStorageService.setLoggedAccount(response.token, response.expiresAt);
      this.currentUserService.setUser(response.user);
      this.currentUserService.setFirstLogin(true);
      this.currentUserService.setLoggedIn(true);
    }
    return response.user;
  }

  getVerificationLink(userId: string): Observable<{ verificationLink: string | undefined }> {
    return this.apiService.getVerificationLink(userId);
  }
}
