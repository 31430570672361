// @ts-strict-ignore
import { Injectable } from '@angular/core';
import { ReCaptchaV3Service } from 'ngx-captcha';

import { AppSettingsConfig } from '@app/core/configs/app-settings.config';
@Injectable({
  providedIn: 'root',
})
export class RecaptchaService {
  constructor(
    private reCaptchaV3Service: ReCaptchaV3Service,
    public appConfig: AppSettingsConfig,
  ) {}

  getCaptchaToken(action): Promise<any> {
    return new Promise((resolve) => {
      return this.reCaptchaV3Service.execute(
        this.appConfig.googleReCaptchaV3Key,
        action,
        (token) => {
          return resolve(token);
        },
        { useGlobalDomain: false },
      );
    });
  }
}
