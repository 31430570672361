<ng-container
  *ngIf="
    !fuseConfig.layout.sidepanel.hidden && fuseConfig.layout.sidepanel.position === 'left'
  "></ng-container>

<div id="main">
  <ng-container *ngIf="fuseConfig.layout.toolbar.position === 'above'">
    <ng-container *ngTemplateOutlet="toolbar"></ng-container>
  </ng-container>

  <div id="container-1" class="container">
    <ng-container *ngIf="fuseConfig.layout.navbar.position === 'left'">
      <ng-container *ngTemplateOutlet="leftNavbar"></ng-container>
    </ng-container>

    <div id="container-2" class="container">
      <ng-container *ngIf="fuseConfig.layout.toolbar.position === 'below-fixed'">
        <ng-container *ngTemplateOutlet="toolbar"></ng-container>
      </ng-container>
      <!-- / TOOLBAR: Below fixed -->

      <div
        id="container-3"
        class="container"
        [class.toolbar-padding]="!fuseConfig.layout.navbar.hidden">
        <ng-container *ngIf="fuseConfig.layout.toolbar.position === 'below-static'">
          <ng-container *ngTemplateOutlet="toolbar"></ng-container>
        </ng-container>
        <content></content>
        <ng-container *ngIf="fuseConfig.layout.footer.position === 'below-static'">
          <ng-container *ngTemplateOutlet="footer"></ng-container>
        </ng-container>
      </div>

      <ng-container *ngIf="fuseConfig.layout.footer.position === 'below-fixed'">
        <ng-container *ngTemplateOutlet="footer"></ng-container>
      </ng-container>
    </div>

    <ng-container *ngIf="fuseConfig.layout.navbar.position === 'right'">
      <ng-container *ngTemplateOutlet="rightNavbar"></ng-container>
    </ng-container>
  </div>

  <ng-container *ngIf="fuseConfig.layout.footer.position === 'above'">
    <ng-container *ngTemplateOutlet="footer"></ng-container>
  </ng-container>
</div>

<ng-container
  *ngIf="
    !fuseConfig.layout.sidepanel.hidden && fuseConfig.layout.sidepanel.position === 'right'
  "></ng-container>

<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- @ PARTIALS                                                                                            -->
<!-- ----------------------------------------------------------------------------------------------------- -->

<ng-template #toolbar>
  <toolbar
    *ngIf="(isLoggedIn$ | async) && !fuseConfig.layout.toolbar.hidden"
    [ngClass]="
      fuseConfig.layout.toolbar.customBackgroundColor === true
        ? fuseConfig.layout.toolbar.position + ' ' + fuseConfig.layout.toolbar.background
        : fuseConfig.layout.toolbar.position
    "></toolbar>
</ng-template>

<ng-template #footer>
  <footer
    *ngIf="!fuseConfig.layout.footer.hidden"
    [ngClass]="
      fuseConfig.layout.footer.customBackgroundColor === true
        ? fuseConfig.layout.footer.position + ' ' + fuseConfig.layout.footer.background
        : fuseConfig.layout.footer.position
    "></footer>
</ng-template>

<ng-template #leftNavbar>
  <fuse-sidebar
    name="navbar"
    class="navbar-fuse-sidebar"
    [folded]="fuseConfig.layout.navbar.folded"
    lockedOpen="gt-md"
    *ngIf="(isLoggedIn$ | async) && !fuseConfig.layout.navbar.hidden">
    <navbar [variant]="fuseConfig.layout.navbar.variant" class="left-navbar"></navbar>
  </fuse-sidebar>
</ng-template>

<ng-template #rightNavbar>
  <fuse-sidebar
    name="navbar"
    position="right"
    class="navbar-fuse-sidebar"
    [folded]="fuseConfig.layout.navbar.folded"
    lockedOpen="gt-md"
    *ngIf="!fuseConfig.layout.navbar.hidden">
    <navbar [variant]="fuseConfig.layout.navbar.variant" class="right-navbar"></navbar>
  </fuse-sidebar>
</ng-template>
