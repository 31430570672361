import { Pipe, PipeTransform } from '@angular/core';
import { UtilsHelper } from '@app/shared/helpers/utils';
import { Moment } from 'moment';

@Pipe({
  name: 'formatDateUtc',
})
export class FormatDateUtc implements PipeTransform {
  transform(date: Moment | Date | string | undefined): string {
    if (!date) return '';
    return UtilsHelper.formatDateUtc(date) || '';
  }
}
