import { RouteType, AircraftType, PreferredTime } from '@app/core/types/common';
import { Attachment, ContractFile } from '@models';
import { Airport } from './airport';
import { Currency } from '@app/core/types/common';

export enum QuotationStatus {
  PENDING = 'pending', //-- pending client verification, from external form
  WAITING_FOR_OFFER = 'waiting_for_offer',
  POSTPONED = 'postponed',
  CANCELLED = 'cancelled',
  DECLINED = 'declined',
  EXPIRED = 'expired',
  OFFER_SENT = 'offer_sent',
  REQUEST_FOR_AMENDMENT = 'request_for_amendment',
  OFFER_CONTRACT_REQUESTED = 'offer_contract_requested', //-- old pre-confirmed
  OFFER_CONTRACT_SENT = 'offer_contract_sent',
  OFFER_CLIENT_CONFIRMED = 'offer_client_confirmed', //-- old pending-confirmation
  ORDER_WAITING = 'order_waiting',
  ORDER_DEPOSIT_PAID = 'order_deposit_paid',
  ORDER_IN_PROGRESS = 'order_in_progress',
  ORDER_COMPLETED = 'order_completed',
  ORDER_PAID = 'order_paid',
  ORDER_CANCELLED = 'order_cancelled',
  ALL_ORDERS = 'all_orders', // This must be here because of order filters
  ORDER_ACTIVE = 'order_active', // This must be here because of order filters
}

export enum OrderAction {
  ORDER_DEPOSIT_PAID = 'order_deposit_paid',
  ORDER_DEPOSIT_UNPAID = 'order_deposit_unpaid',
  ORDER_PAID = 'order_paid',
  ORDER_UNPAID = 'order_unpaid',
  ORDER_CANCELLED = 'order_cancelled',
  ORDER_UNCANCELLED = 'order_uncancelled',
}

export type QuotationAvailability = {
  availabilityOutbound?: 'available' | 'partially_available' | 'not_available';
  availabilityInbound?: 'available' | 'partially_available' | 'not_available';
};

export type QuotationRoute = {
  _id: string;
  fromAirport: Airport;
  toAirport: Airport;
  departureDate: string;
  departureTime?: string;
  departureTimePreferred?: PreferredTime;
  passengers: number;
  duration?: string;
  distanceKM?: number;
  airplane?: {
    _id: string;
    capacitySeats?: number;
    regNum?: string;
    aircraft?: {
      _id: string;
      type: AircraftType;
      name?: string;
    };
  };
  aircraftType?: AircraftType;
  notPartOfOffer?: boolean;
  capacitySeats?: number;
};

export type EmergencyContact = {
  emergencyPhone?: string;
  emergencyEmail?: string;
  emergencyName?: string;
};

export type SpecialRequest = {
  name: string;
  price: number;
  taxFreePrice: number;
  taxPercent: number;
  amount: number;
  currency?: Currency;
};

export type Quotation = {
  _id: string;
  referenceNumber: number;
  flexibleDates: boolean;
  groupType?: string;
  otherRemarks?: string;
  declineReason?: string;
  status: QuotationStatus;
  requestForAmendment?: string;
  declinedByAirline?: string;
  declinedByClient?: string;
  offerCancelledByAirline?: string;
  requestCancelledByClient?: string;
  validUntil?: string;
  price?: number;
  currency: Currency;
  commission?: number;
  totalPrice?: number;
  priceConditions?: any;
  extraPriceConditions?: string;
  clientEmergencyContact?: EmergencyContact;
  airlineEmergencyContact?: EmergencyContact;
  contractTerms?: Attachment[];
  attachments?: Attachment[];
  originalAirplane?: string;
  originalAircraftType?: AircraftType;
  client: {
    _id: string;
    name: string;
    adminNotes?: string;
    noCommission?: boolean;
    airlineInvoicesDirectly?: boolean;
  };
  airline: {
    _id: string;
    name: string;
    adminNotes?: string;
    configuration?: {
      showFlightPrice: boolean;
    };
    termsAndConditions: ContractFile[];
    commissionPercent?: number;
    commissionMinimum?: number;
    currency: Currency;
    paxListTemplates?: Attachment[];
  };
  estimatedOfferDate?: string;
  estimatedOfferPrice?: number;
  offerSentAt?: string;
  confirmedByAirline?: string;
  confirmedByClient?: string;
  contractRequestedAt?: string;
  contractSentAt?: string;
  orderNumber?: number;
  paxList?: Attachment[];
  crewNotes?: string;
  groundServiceNotes?: string;
  cateringNotes?: string;
  invoiceAttachments?: Attachment[];
  specialRequests?: SpecialRequest[];
  createdAt: string;
  paidAt?: string;
  depositDueDate?: string;
  depositDueDateClient?: string;
  depositPaidAt?: string;
  routeType: RouteType;
  routes: QuotationRoute[];
  offeredRouteCount?: number;
  enquiry?: {
    _id: string;
    routes?: {
      fromAirport: string;
      toAirport: string;
      departureDate: string;
    }[];
  };

  airplane?: {
    _id: string;
    capacitySeats?: number;
    regNum?: string;
    aircraft?: {
      _id: string;
      type: AircraftType;
      name?: string;
    };
  };
  aircraftType?: AircraftType;
  capacitySeats?: number;

  adminNotes?: string;
  paymentDue?: string;
  paymentDueClient?: string;
  subscribed?: boolean;
  updatedAt: string;
};

export type QuotationListItem = {
  _id: string;
  referenceNumber: number;
  totalPrice?: number;
  price?: number;
  commission?: number;
  currency?: Currency;
  status: QuotationStatus;
  estimatedOfferPrice?: number;
  estimatedOfferDate?: string;
  confirmedByAirline?: string;
  updatedAt: string;
  createdAt: string;
  client: {
    _id: string;
    name: string;
    type: string;
  };
  airline: {
    _id: string;
    name: string;
  };
  routes: {
    airplane?: {
      aircraft: {
        name: string;
      };
    };
    aircraftType: AircraftType;
    fromAirport: Airport;
    toAirport: Airport;
    departureDate: string;
    passengers: number;
  }[];
  availability?: QuotationAvailability;
  passengerRangeString?: string;
};

export type QuotationRouteToUpdate = {
  _id?: string;
  fromAirportId: string;
  toAirportId: string;
  departureDate: string;
  departureTime?: string;
  departureTimePreferred?: string;
  airplaneId?: string;
  aircraftType?: AircraftType;
  capacitySeats?: number;
  passengers?: number;
  notPartOfOffer?: boolean;
};

export type QuotationToUpdate = {
  routes?: QuotationRouteToUpdate[];
  price?: number;
  currency?: Currency;
  commission?: number;
  depositDueDate?: string;
  depositDueDateClient?: string;
  paymentDue?: string;
  paymentDueClient?: string;
  validUntil?: string;
  priceConditions?: PriceCondition[];
  extraPriceConditions?: string;
  airplaneId?: string;
  aircraftType?: AircraftType;
  capacitySeats?: number;
  routeType?: string;
  otherRemarks?: string;
  adminNotes?: string;
  paidAt?: string;
  depositPaidAt?: string;
  clientEmergencyContact?: {
    emergencyPhone?: string;
    emergencyEmail?: string;
    emergencyName?: string;
  };
  airlineEmergencyContact?: {
    emergencyPhone?: string;
    emergencyEmail?: string;
    emergencyName?: string;
  };
};

export type QuotationStatusFilter = {
  status: QuotationStatus[];
};

export type PriceCondition = {
  text: string;
  isSelected: boolean;
};

export type QuotationResponse = { quotations: QuotationListItem[]; total: number };
export type QuotationResponseWithError = QuotationResponse & { error?: Error };

export type PostponedData = {
  estimatedOfferDate: string;
  estimatedOfferPrice?: string;
};
