import { Component, OnInit } from '@angular/core';
import { ApiService } from '@app/core/services';
import { HttpClient } from '@angular/common/http';
import { ConfirmDialogComponent } from '@app/shared/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import versionJson from '@assets/version.json';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.scss'],
})
export class VersionComponent implements OnInit {
  backendVersion: VersionInfo;
  uiVersion: VersionInfo;
  dialogOpen = false;

  constructor(
    private _apiService: ApiService,
    private _matDialog: MatDialog,
    private translateService: TranslateService,
    private http: HttpClient,
  ) {
    this.backendVersion = { version: '' };
    this.uiVersion = versionJson;
  }

  fetchUIVersion(): void {
    if (!this.dialogOpen) {
      this.http.get<VersionInfo>('/assets/version.json').subscribe((data) => {
        if (data.version !== this.uiVersion.version) {
          this.dialogOpen = true;
          this._matDialog
            .open(ConfirmDialogComponent, {
              width: '350px',
              data: {
                messageKey: this.translateService.instant('dialog.versionNumberChanged'),
                actionKey: '',
                confirmBtnKey: this.translateService.instant('dialog.reloadPage'),
                cancelBtnKey: this.translateService.instant('dialog.remindMeLater'),
              },
            })
            .afterClosed()
            .subscribe((result) => {
              if (result === 'Confirm') {
                /** Enforce reloading to bypass cache by injecting a reload-parameter **/
                if (location.href.indexOf('?') > 0) {
                  location.href = location.href + '&reload=' + new Date().getMilliseconds();
                } else {
                  location.href = location.href + '?reload=' + new Date().getMilliseconds();
                }
              }
              this.dialogOpen = false;
            });
        }
      });
    }
  }

  ngOnInit(): void {
    //-- get the backend version from api
    this._apiService.getVersion().subscribe((data) => {
      this.backendVersion = JSON.parse(data);
    });

    /** Fetch version every N seconds **/
    setInterval(() => this.fetchUIVersion(), 1000 * 30);
  }

  parseVersion(): string {
    let version = '';
    if (this.uiVersion) version += 'FE-' + this.uiVersion.version;
    if (this.backendVersion) version += '-BE-' + this.backendVersion.version;
    return version;
  }
}

export interface VersionInfo {
  version: string;
}
