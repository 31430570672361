import { Airplane } from '@app/core/models';
import { AirplaneListItem } from '@app/core/types/airplane';

/**
 * This file includes common and helper types
 */
export const SEARCH_DEBOUNCE_TIME: number = 1200; //-- search debounce time in ms
export const SELECT_INPUT_DEBOUNCE_TIME: number = 500; //-- select field debounce time in ms
export type Pagination = { pageSize: number; pageIndex: number }; // PageIndex starting from 0
export const DEFAULT_PAGINATION = { pageSize: 50, pageIndex: 0 };

export type RequestPagination = { pageSize: number; page: number }; // Page starting from 1

export type Sorting = { sortBy: string | undefined; sortDir: string | undefined };

export type PreferredTime =
  | '6AM - 12PM'
  | '12PM - 18PM'
  | '18PM - 24PM'
  | '24PM - 6AM'
  | 'Flexible';

export const PreferredTimes: PreferredTime[] = [
  '6AM - 12PM',
  '12PM - 18PM',
  '18PM - 24PM',
  '24PM - 6AM',
  'Flexible',
];

export type AircraftType =
  | 'Narrow body'
  | 'Very Large Aircraft'
  | 'Regional Jet'
  | 'Business Jet'
  | 'Commuter'
  | 'Wide body'
  | 'Other';

export const AircraftTypes: AircraftType[] = [
  'Commuter',
  'Business Jet',
  'Regional Jet',
  'Narrow body',
  'Wide body',
  'Very Large Aircraft',
  'Other',
];

export type RouteType = 'return' | 'oneWay' | 'multiLeg' | 'flightSeries';

export enum RouteTypeEnum {
  Return = 'return',
  OneWay = 'oneWay',
  MultiLeg = 'multiLeg',
  FlightSeries = 'flightSeries',
}

export const RouteTypes = [
  RouteTypeEnum.Return,
  RouteTypeEnum.OneWay,
  RouteTypeEnum.MultiLeg,
  RouteTypeEnum.FlightSeries,
];

export const Currencies = ['EUR', 'USD', 'GBP', 'CHF', 'DKK', 'NOK', 'SEK'] as const;

export enum Currency {
  EUR = 'EUR',
  USD = 'USD',
  GBP = 'GBP',
  CHF = 'CHF',
  DKK = 'DKK',
  NOK = 'NOK',
  SEK = 'SEK',
}

export enum ClientType {
  TRAVEL_AGENCY = 'travel-agency',
  FLIGHT_BROKER = 'flight-broker',
  CONSUMER = 'consumer',
  OTHER_COMPANY = 'other-company',
}

export type UserMode = 'register' | 'login';

export type BookingSource = 'normal' | 'external';

export type ApiError = {
  status: string;
  message: string;
  code: string;
  translationKey: string;
  translationParams?: any[];
};
