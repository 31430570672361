<img style="object-fit: content" *ngIf="previewType == 'image' && src" [src]="src" />

<div fxLayout="column" *ngIf="previewType == 'pdf'" class="icon">
  <img style="object-fit: content" src="/assets/icons/pdf-icon.svg" width="100" />
</div>
<div fxLayout="column" *ngIf="previewType == 'spreadsheet'" class="icon">
  <img style="object-fit: content" src="/assets/icons/spreadsheet-icon.svg" width="100" />
</div>
<div fxLayout="column" *ngIf="previewType == 'document'" class="icon">
  <img style="object-fit: content" src="/assets/icons/document-icon.svg" width="100" />
</div>
