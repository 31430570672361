// @ts-strict-ignore
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from '@app/core/services';
import { QuotationService } from '@app/main/quotation/quotation.service';
import { Attachment } from '@app/core/models';

import { saveAs } from 'file-saver';
import { FileDownloadFileType } from '@app/shared/components/attachment-list/attachment-list.component';

@Component({
  selector: 'app-file-preview-dialog',
  templateUrl: './file-preview-dialog.component.html',
  styleUrls: ['./file-preview-dialog.component.scss'],
})
export class FilePreviewDialogComponent implements OnInit {
  src: string;
  fileExtension: string = undefined;
  attachment: Attachment;
  typePrivateFile: FileDownloadFileType;
  dialog: boolean = false;
  objectId: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public _matDialog: MatDialog,
    public matDialogRef: MatDialogRef<FilePreviewDialogComponent>,
    private apiService: ApiService,
    private quotationService: QuotationService,
  ) {}

  ngOnInit() {
    if (this.data) {
      this.objectId = this.data.objectId;
      this.attachment = this.data.attachment;
      this.typePrivateFile = this.data.typePrivateFile;
      this.src = this.attachment.cloudFilename;
      this.dialog = this.data.dialog || false;

      this.fileExtension = this.src.substring(this.src.lastIndexOf('.') + 1);
    }
  }

  download() {
    let headerType;
    const attachment = this.attachment;

    switch (this.fileExtension.toLowerCase()) {
      case 'doc':
        headerType = { type: 'application/msword' };
        break;

      case 'docx':
        headerType = {
          type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        };
        break;

      case 'xls':
      case 'xlsx':
        headerType = { type: 'application/vnd.ms-excel' };
        break;

      case 'pdf':
        headerType = { type: 'application/pdf' };
        break;

      case 'csv':
        headerType = { type: 'text/csv' };
        break;

      case 'png':
      case 'jpg':
      case 'jpeg':
        headerType = { type: 'image/' + this.fileExtension };
        break;

      default:
        console.error('*** UNSUPPORTED FILE TYPE: ', this.fileExtension);
        return;
    }

    let download;
    switch (this.typePrivateFile) {
      case FileDownloadFileType.AIRLINE_ATTACHMENT:
        download = this.apiService.downloadAirlineAttachment(this.objectId, this.src);
        break;
      case FileDownloadFileType.PAX_LIST_TEMPLATE:
        download = this.apiService.downloadAirlinePaxListTemplate(this.objectId, this.src);
        break;
      default:
        download = this.quotationService.download(this.objectId, this.src, this.typePrivateFile);
    }
    download.subscribe((data) => {
      let blob = new Blob([data], headerType);
      const blobUrl = window.URL.createObjectURL(blob);
      saveAs(blobUrl, attachment.originalFilename);
    });
  }
}
