import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@app/shared/shared.module';
import { FuseSearchBarModule, FuseShortcutsModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';
import { ToolbarComponent } from 'app/layout/components/toolbar/toolbar.component';
import { AvatarModule } from 'ngx-avatars';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';

@NgModule({
  declarations: [ToolbarComponent],
  imports: [
    RouterModule,
    SharedModule,
    FuseSharedModule,
    FuseSearchBarModule,
    FuseShortcutsModule,
    AvatarModule,
    MatListModule,
  ],
  exports: [ToolbarComponent],
})
export class ToolbarModule {}
