import { Directive, ElementRef } from '@angular/core';
import { NgControl, Validators } from '@angular/forms';

@Directive({
  selector: 'input[formControlName],input[formControl]',
})
export class AddRequiredPlaceholderDirective {
  constructor(
    private control: NgControl,
    private element: ElementRef,
  ) {}

  ngAfterViewInit() {
    if (
      (this.element.nativeElement.required ||
        this.control.control?.hasValidator(Validators.required)) &&
      !this.element.nativeElement.disabled &&
      this.element.nativeElement.getAttribute('type') !== 'password' &&
      !this.element.nativeElement.hasAttribute('placeholder')
    ) {
      this.element.nativeElement.placeholder = 'Required';
    }
  }
}
