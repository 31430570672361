import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  constructor() {}

  getCurrentUser() {
    let json: any;
    try {
      json = localStorage.getItem('userInfo')
        ? JSON.parse(localStorage.getItem('userInfo') || '')
        : null;
    } catch (err) {
      console.warn(err);
      json = null;
    }
    return json;
  }

  setLoggedAccount(token: string, expiresAt: string) {
    localStorage.setItem('userInfo', JSON.stringify({ token, expiresAt }));
  }

  removeLoggedAccount() {
    localStorage.removeItem('userInfo');
  }

  getUserLang(): string {
    return localStorage.getItem('lang') || '';
  }

  setUserLang(lang: string): void {
    if (lang) localStorage.setItem('lang', lang);
  }
}
