// @ts-strict-ignore
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { navigation } from 'app/navigation/navigation';
import { CurrentUserService } from '@app/core/services';
import { BreadcrumbService } from 'xng-breadcrumb';

@Component({
  selector: 'vertical-layout-1',
  templateUrl: './layout-1.component.html',
  styleUrls: ['./layout-1.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class VerticalLayout1Component implements OnInit, OnDestroy {
  isLoggedIn$: Observable<boolean>;
  fuseConfig: any;
  navigation: any;
  info: any;
  showBreadcrumbs: boolean = false;
  filterCollapseReceived: boolean = null;
  // Private
  private _unsubscribeAll: Subject<void>;

  /**
   * Constructor
   *
   * @param {FuseConfigService} _fuseConfigService
   */
  constructor(
    private _fuseConfigService: FuseConfigService,
    private currentUserService: CurrentUserService,
    private breadcrumbService: BreadcrumbService,
  ) {
    // Set the defaults
    this.navigation = navigation;

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.isLoggedIn$ = this.currentUserService.isLoggedIn;
    this.breadcrumbService.breadcrumbs$.pipe(takeUntil(this._unsubscribeAll)).subscribe((data) => {
      if (data.length > 0) {
        data.forEach((item) => {
          if (item.info !== undefined) {
            switch (item.info) {
              case 'Map':
              case 'Home':
              case 'Login':
                this.showBreadcrumbs = false;
                break;

              default:
                this.showBreadcrumbs = true;
                break;
            }
          }
        });
      }
    });

    // Subscribe to config changes
    this._fuseConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe((config) => {
      this.fuseConfig = config;
    });
  }
  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
