import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

const DEFAULT_WARNING_MESSAGE_TRANSLATION_KEY = 'unsavedChanges.warning';

@Injectable({
  providedIn: 'root',
})
export class UnsavedChangesService {
  hasUnsavedChanges = new BehaviorSubject<boolean>(false);
  private _warningMessage: string = '';

  constructor(
    private router: Router,
    private translateService: TranslateService,
  ) {
    this.setWarningMessage(DEFAULT_WARNING_MESSAGE_TRANSLATION_KEY);

    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.hasUnsavedChanges.next(false);
        this.setWarningMessage(DEFAULT_WARNING_MESSAGE_TRANSLATION_KEY);
      }
    });
  }

  get warningMessage(): string {
    return this.translateService.instant(this._warningMessage);
  }

  shouldShowWarningConfirm(): boolean {
    return this.hasUnsavedChanges.value;
  }

  setWarningMessage(translateKey: string) {
    this._warningMessage = translateKey;
  }
}
