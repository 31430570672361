// @ts-strict-ignore
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { fuseAnimations } from '@fuse/animations';
import { CurrentUserService } from '@app/core/services';
import { User } from '@app/core/types/user';

@Component({
  selector: 'app-welcome-dialog',
  templateUrl: './welcome-dialog.component.html',
  styleUrls: ['./welcome-dialog.component.scss'],
  animations: fuseAnimations,
})
export class WelcomeDialogComponent implements OnInit {
  user: User;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<WelcomeDialogComponent>,
    private _currentUserService: CurrentUserService,
    private _matDialog: MatDialog,
  ) {}

  ngOnInit() {
    this.user = this._currentUserService.getUser();
  }

  onClose() {
    this.dialogRef.close();
  }
}
