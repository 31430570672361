// @ts-strict-ignore
import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { BehaviorSubject, Observable } from 'rxjs';
import * as XLSX from 'xlsx';

@Injectable()
export class ExcelService {
  public airplanesToImport: BehaviorSubject<any>;
  public paxToImport: BehaviorSubject<any>;
  toasterService: any;
  constructor() {
    this.airplanesToImport = new BehaviorSubject([]);
    this.paxToImport = new BehaviorSubject([]);
  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    let auxArr = [];
    //Compose correct columns to CSV
    json.forEach((element) => {
      let auxObj = {
        _id: '',
        aircraftType: '',
        // aircraft: '',
        aircraftName: '',
        // aircraftCode: '',
        // airline: '',
        airline: '',
        // airlineName: '',
        capacitySeats: '',
        capacityCargoInKg: '',
        // airport: '',
        // airportName: '',
        airportIataCode: '',
        feePerFlightHour: '',
        transferFeePerFlightHour: '',
        startFeeInEUR: '',
        feePerKmInEur: '',
        transferFeePerKmInEUR: '',
      };
      if (element.aircraft !== undefined) {
        auxObj._id = element._id;
        auxObj.aircraftType = element.aircraftType;
        // auxObj.aircraft = element.aircraft._id;
        auxObj.aircraftName = element.aircraft.name;
        // auxObj.aircraftCode = element.aircraft.code;
        // auxObj.airline = element.airline._id;
        auxObj.airline = element.airline;
        // auxObj.airlineName = element.airline.name;
        //auxObj.airlineId = element.airline.airlineId;
        auxObj.capacitySeats = element.capacitySeats || '';
        auxObj.capacityCargoInKg = element.capacityCargoInKg || '';
        // auxObj.airport = element.airport._id;
        // auxObj.airportName = element.airport.name;
        auxObj.airportIataCode = element.airport.iataCode;
        auxObj.startFeeInEUR = element.price.startFeeInEUR || '';
        auxObj.feePerKmInEur = element.price.feePerKmInEur || '';
        auxObj.transferFeePerKmInEUR = element.price.transferFeePerKmInEUR || '';
        auxObj.feePerFlightHour = element.price.feePerFlightHour || '';
        auxObj.transferFeePerFlightHour = element.price.transferFeePerFlightHour || '';
        auxArr.push(auxObj);
      }
    });

    const ws = XLSX.utils.json_to_sheet(auxArr);
    const csv = XLSX.utils.sheet_to_csv(ws, { FS: ';' });
    const blob = new Blob([csv], { type: 'text/csv;charset=UTF-8' });
    saveAs(blob, excelFileName);
  }

  public exportPaxAsExcelFile(json: any[], excelFileName: string): void {
    let auxArr = [];
    //Compose correct columns to CSV
    json.forEach((element) => {
      let auxObj = {
        _id: '',
        firstName: '',
        lastName: '',
        dateOfBirth: '',
        email: '',
      };
      if (element.passenger !== undefined) {
        auxObj._id = element._id;
        auxObj.firstName = element.firstName;
        // auxObj.aircraft = element.aircraft._id;
        auxObj.lastName = element.lastName;
        // auxObj.aircraftCode = element.aircraft.code;
        // auxObj.airline = element.airline._id;
        auxObj.dateOfBirth = element.dateOfBirth;
        // auxObj.airlineName = element.airline.name;
        auxObj.email = element.email;
        auxArr.push(auxObj);
      }
    });

    const ws = XLSX.utils.json_to_sheet(auxArr);
    const csv = XLSX.utils.sheet_to_csv(ws, { FS: ';' });
    const blob = new Blob([csv], { type: 'text/plain;charset=UTF-8' });
    saveAs(blob, excelFileName);
  }

  public importFile(obj, listOf: string): Observable<any> {
    let wb;
    let rABS = false;

    if (!obj) {
      return;
    }
    var f = obj[0];
    var reader = new FileReader();
    var self = this;
    reader.onload = function (e) {
      var data = e.target.result;

      if (rABS) {
        wb = XLSX.read(btoa(fixdata(data)), {
          // Conversión manual
          type: 'base64',
        });
      } else {
        wb = XLSX.read(data, {
          type: 'binary',
        });
      }
      //wb.SheetNames[0] es el nombre de la primera hoja en Obtener hojas
      //wb.Sheets[Sheet name] Obtenga los datos de la primera Hoja
      console.log(XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]));
      if (listOf === 'airplanes') {
        self.airplanesToImport.next(XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]));
      } else {
        self.paxToImport.next(XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]));
      }
      return XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
    };
    if (rABS) {
      reader.readAsArrayBuffer(f);
    } else {
      reader.readAsBinaryString(f);
    }
  }
}
function fixdata(data) {
  // Flujo de archivos BinaryString
  var o = '',
    l = 0,
    w = 10240;
  for (; l < data.byteLength / w; ++l)
    o += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w, l * w + w)));
  o += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w)));
  return o;
}
