import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService, CurrentUserService } from '@app/core/services';

@Injectable()
export class RoleGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private currentUserService: CurrentUserService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const expectedRoles: string = route.data.expectedRoles;
    const user = this.currentUserService.getUser();

    if (!expectedRoles || (user.role && expectedRoles.includes(user.role))) {
      return true;
    } else {
      this.router.navigate(['/dashboard'], {
        state: { requestedUrl: state.url, insufficientPermissions: true },
      });
      return false;
    }
  }
}
