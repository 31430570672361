// @ts-strict-ignore
import {
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';

import { NavigationEnd, Router } from '@angular/router';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';

import { navigation } from 'app/navigation/navigation';

import { GoogleMapService } from '@app/core/services/googleMap.service';
import { AppSettingsConfig } from '@app/core/configs/app-settings.config';
import { UtilsHelper } from '@app/shared/helpers/utils';

import {
  AuthenticationService,
  CurrentUserService,
  LocalStorageService,
  ToasterService,
} from '@app/core/services';

@Component({
  selector: 'app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  fuseConfig: any;
  navigation: any;
  username: string;
  sub: Subscription;

  // Private
  private _unsubscribeAll: Subject<void>;

  constructor(
    @Inject(DOCUMENT) private document: any,
    private _fuseConfigService: FuseConfigService,
    private _fuseNavigationService: FuseNavigationService,
    private _fuseSidebarService: FuseSidebarService,
    private _fuseSplashScreenService: FuseSplashScreenService,
    private _translateService: TranslateService,
    private _platform: Platform,
    private _appConfig: AppSettingsConfig,
    private _localStorageService: LocalStorageService,
    private currentUserService: CurrentUserService,
    private authenticationService: AuthenticationService,
    private _googleMapService: GoogleMapService,

    private cd: ChangeDetectorRef,
    private idle: Idle,
    private router: Router,
    private toasterService: ToasterService,
  ) {
    // Get default navigation
    this.navigation = navigation;

    // Register the navigation to the service
    this._fuseNavigationService.register('main', this.navigation);

    // Set the main navigation as our current navigation
    this._fuseNavigationService.setCurrentNavigation('main');

    // Add is-mobile class to the body if the platform is mobile
    if (this._platform.ANDROID || this._platform.IOS) {
      this.document.body.classList.add('is-mobile');
    }

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.configureI18n();
    this.configureIdleTimeout();

    this.sub = this.currentUserService.isLoggedIn.subscribe((userLoggedIn) => {
      if (userLoggedIn) {
        let user = this.currentUserService.getUser();
        this.username = user.username;

        /** Show yBug for logged in users but do NOT show for external forms**/
        if (UtilsHelper.isExternalPage() === false) {
          this.injectYbug();
          this.idle.watch(); // Start watching idle after login
        }
      } else {
        this.idle.stop(); // Stop watching idle after logout
      }
    });

    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      this._fuseSplashScreenService.hide();
    });

    // Subscribe to config changes
    this._fuseConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe((config) => {
      this.fuseConfig = config;

      // Boxed
      if (this.fuseConfig.layout.width === 'boxed') {
        this.document.body.classList.add('boxed');
      } else {
        this.document.body.classList.remove('boxed');
      }

      // Color theme - Use normal for loop for IE11 compatibility
      for (let i = 0; i < this.document.body.classList.length; i++) {
        const className = this.document.body.classList[i];

        if (className.startsWith('theme-')) {
          this.document.body.classList.remove(className);
        }
      }

      this.document.body.classList.add(this.fuseConfig.colorTheme);
    });
  }

  @HostListener('click') onClick() {
    if (this.toasterService.isVisible()) {
      setTimeout(() => {
        this.toasterService.dismiss();
      }, 2000);
    }
  }

  private configureIdleTimeout() {
    this.idle.setIdle(6 * 60 * 60); // 6 hours in seconds
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    // Log out user after idle timeout
    this.idle.onIdleStart.subscribe(() => {
      if (UtilsHelper.isExternalPage() === false) {
        /** Logout user if we are not on external pages (do nothing if we are) **/
        this.authenticationService.signOut();
      }
    });
    this.idle.onIdleEnd.subscribe(() => {
      this.cd.detectChanges(); // how do i avoid this kludge? (from: https://moribvndvs.github.io/ng2-idle/quickstart)
    });
  }

  private configureI18n() {
    this._translateService.addLangs(this._appConfig.supportedLanguages);
    this._translateService.setDefaultLang(this._appConfig.defaultLocale);
    const userLang = this._localStorageService.getUserLang();
    this._translateService.use(userLang || this._appConfig.defaultLocale);
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
    this.sub.unsubscribe();
  }

  injectYbug(): void {
    if (!window['ybug_settings']) {
      window['ybug_settings'] = { id: '0xbq7v1r0mdgg3zwsa5c' };
      var ybug = document.createElement('script');
      ybug.type = 'text/javascript';
      ybug.async = true;
      ybug.src = 'https://widget.ybug.io/button/' + window['ybug_settings'].id + '.js';
      var s = document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(ybug, s);
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebarOpen(key): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }
}
