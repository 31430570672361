// @ts-strict-ignore
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { CurrentUserService } from '@app/core/services';
import { FilePreviewDialogComponent } from '../file-preview/file-preview-dialog/file-preview-dialog.component';
import { NewQuotation, Attachment } from '@app/core/models';
import { User } from '@app/core/types/user';
import { FileDownloadFileType } from '@app/shared/components/attachment-list/attachment-list.component';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
  animations: fuseAnimations,
})
export class ConfirmDialogComponent implements OnInit {
  messageKey = 'confirmDialog.message';
  messageKeyParams = {};
  titleKey = 'confirmDialog.title';
  titleKeyParams = {};
  actionKey: string = 'confirmDialog.action';
  actionKeyParams = {};
  showConfirmBtn = true;
  showConfirmMsg = true;
  showMyPlaneCheck = false;
  showCancelBtn = true;
  myPlaneCheck = false;
  confirmBtnKey = 'forms.confirmBtn';
  cancelBtnKey = 'forms.cancelBtn';
  termsToAccept = false;
  showTermsToAccept = false;
  showTermsToAcceptLayout = false;
  serviceName: string = '';
  confirmExtraText: string;
  additionalDocumentsToAccept = [];
  uploadedDocumentsToAccept = [];
  public withReplyMessage = false;
  documentCount = 0;

  termsAcceptedCount = 0;
  showTerms = false;
  mustAcceptTerms = true;
  termsAccepted = true;

  user: User;
  companySpecificTerms = '';

  public confirmForm = new UntypedFormGroup({
    message: new UntypedFormControl(''),
  });
  quotation: NewQuotation;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ConfirmDialogComponent>,
    private _currentUserService: CurrentUserService,
    private _matDialog: MatDialog,
  ) {}

  ngOnInit() {
    this.user = this._currentUserService.getUser();
    if (this.data && this.data.messageKey) {
      this.messageKey = this.data.messageKey;
    }
    if (this.data && this.data.messageKeyParams) {
      this.messageKeyParams = this.data.messageKeyParams;
    }
    if (this.data && this.data.titleKey) {
      this.titleKey = this.data.titleKey;
    }
    if (this.data && this.data.titleKeyParams) {
      this.titleKeyParams = this.data.titleKeyParams;
    }
    if (this.data && this.data.actionKey !== undefined) {
      this.actionKey = this.data.actionKey;
    }
    if (this.data && this.data.actionKeyParams) {
      this.actionKeyParams = this.data.actionKeyParams;
    }
    if (this.data && this.data.showConfirmBtn === false) {
      this.showConfirmBtn = false;
    }
    if (this.data && this.data.confirmBtnKey) {
      this.confirmBtnKey = this.data.confirmBtnKey;
    }
    if (this.data && this.data.showConfirmMsg === false) {
      this.showConfirmMsg = false;
    }
    if (this.data && this.data.cancelBtnKey) {
      this.cancelBtnKey = this.data.cancelBtnKey;
    }
    if (this.data && this.data.showCancelBtn === false) {
      this.showCancelBtn = this.data.showCancelBtn;
    }
    if (this.data && this.data.showTermsToAccept) {
      this.showTermsToAccept = this.data.showTermsToAccept;
    }
    if (this.data && this.data.mustAcceptTerms === false) {
      this.mustAcceptTerms = this.data.mustAcceptTerms;
    }
    if (this.data && this.data.showTermsToAcceptLayout) {
      this.showTermsToAcceptLayout = this.data.showTermsToAcceptLayout;
    }
    if (this.data && this.data.additionalDocumentsToAccept) {
      this.additionalDocumentsToAccept = this.data.additionalDocumentsToAccept;
    }
    if (this.data && this.data.uploadedDocumentsToAccept) {
      this.uploadedDocumentsToAccept = this.data.uploadedDocumentsToAccept;
    }
    if (this.data && this.data.serviceName) {
      this.serviceName = this.data.serviceName;
    }
    if (this.data && this.data.showMyPlaneCheck) {
      this.showMyPlaneCheck = this.data.showMyPlaneCheck;
    }
    if (this.data && this.data.confirmExtraText) {
      this.confirmExtraText = this.data.confirmExtraText;
    }
    this.withReplyMessage = this.data && this.data.withReplyMessage;
    if (this.data && this.data.defaultMessage) {
      this.confirmForm.patchValue({ message: this.data.defaultMessage });
    }
    if (this.data && this.data.quotation) {
      this.quotation = this.data.quotation;
    }

    this.documentCount =
      this.additionalDocumentsToAccept.length + this.uploadedDocumentsToAccept.length;

    if (this.documentCount > 0) {
      this.termsAccepted = !this.mustAcceptTerms;
      this.showTerms = true;
    }

    if (this.showTermsToAccept) {
      this.setCompanySpecificTerms();
    }
  }

  setCompanySpecificTerms() {
    if (
      this.user.client &&
      (this.user.client.name === 'American Express GBT Finland Oy' ||
        this.user.client.name === 'American Express GBT Denmark' ||
        this.user.client.name === 'American Express GBT Norway' ||
        this.user.client.name === 'American Express GBT Sweden' ||
        this.user.client.name == 'AMEX-test')
    ) {
      this.termsAccepted = false;
      this.showTerms = true;
      this.companySpecificTerms = 'amex';
    }
  }

  changeAcceptTerms(event) {
    if (event.checked) {
      this.termsAccepted = true;
    } else {
      this.termsAccepted = false;
    }
  }

  changeCheckMyPlane() {
    this.myPlaneCheck = !this.myPlaneCheck;
  }
  openTerms() {
    this.showTermsToAcceptLayout = true;
  }
  closeTerms() {
    this.showTermsToAcceptLayout = false;
    if (this.data.showTermsToAcceptLayout) {
      this.dialogRef.close();
    }
  }
  onCloseConfirm() {
    this.dialogRef.close('Confirm');
  }
  onCloseCancel() {
    this.dialogRef.close('Cancel');
  }

  openPreview(event, attachment: Attachment) {
    event.stopPropagation();
    event.preventDefault();

    const data = {
      attachment,
      objectId: this.quotation._id,
      typePrivateFile: FileDownloadFileType.CONTRACT_TERMS,
      dialog: true,
    };
    this._matDialog.open(FilePreviewDialogComponent, {
      panelClass: 'contract-terms-dialog-attachment',
      data,
    });
  }
}
