import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AuthenticationService, CurrentUserService } from '@app/core/services';

@Injectable()
export class LoginGuard {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private currentUserService: CurrentUserService,
  ) {}

  resolve(route: ActivatedRouteSnapshot): void {
    const isLogout = route?.firstChild?.url?.[0]?.path === 'logout';
    if (isLogout) return;

    if (this.authenticationService.isAuthenticated()) {
      const currentUser = this.currentUserService.getUser();
      if (currentUser && currentUser.client && currentUser.client.bookingAccessDisabled) {
        this.router.navigate(['/list/offer']);
      } else {
        this.router.navigate(['/dashboard']);
      }
    }
  }
}
