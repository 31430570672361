<div class="pagination">
  <div
    *ngIf="length == 0 && showNoResults"
    fxFlex="100"
    fxLayout="row"
    fxLayoutAlign="center center"
    style="font-size: 13px">
    {{ 'pagination.noResults' | translate }}
  </div>
  <div class="items-per-page" *ngIf="length > 0 && pageSizeOptions.length > 1">
    <div class="items-per-page-label" fxHide.lt-sm>
      {{ 'pagination.itemsPerPage' | translate }}:
    </div>
    <mat-form-field class="items-per-page-form-field">
      <mat-select
        [formControl]="pageSizeValue"
        class="items-per-page-select"
        style="margin-top: -8px; margin-bottom: -2px"
        (selectionChange)="onChangePageSize($event)">
        <mat-option *ngFor="let option of pageSizeOptions" [value]="option">
          {{ option }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="right-container" fxLayout="row" *ngIf="length > 0">
    <div class="page-range-label" data-test="page-range">{{ pageRangeLabel }}</div>

    <div class="action-buttons" fxLayout="row" *ngIf="hasNextPage || hasPreviousPage">
      <button
        type="button"
        mat-icon-button
        class="mat-mdc-paginator-navigation-previous"
        data-test="previous-page-button"
        (click)="onClickPrevPage()"
        [attr.aria-label]="'pagination.previousPageLabel' | translate"
        [matTooltip]="'pagination.previousPageLabel' | translate"
        [matTooltipDisabled]="!hasPreviousPage"
        [matTooltipPosition]="'above'"
        [disabled]="!hasPreviousPage">
        <svg class="paginator-icon" viewBox="0 0 24 24" focusable="false">
          <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
        </svg>
      </button>

      <button
        type="button"
        mat-icon-button
        class="mat-mdc-paginator-navigation-next"
        data-test="next-page-button"
        (click)="onClickNextPage()"
        [attr.aria-label]="'pagination.nextPageLabel' | translate"
        [matTooltip]="'pagination.nextPageLabel' | translate"
        [matTooltipDisabled]="!hasNextPage"
        [matTooltipPosition]="'above'"
        [disabled]="!hasNextPage">
        <svg class="paginator-icon" viewBox="0 0 24 24" focusable="false">
          <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
        </svg>
      </button>
    </div>
  </div>
</div>
