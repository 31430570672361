import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@env/environment';
import { LocalStorageService } from '@app/core/services';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private localStorageService: LocalStorageService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add auth header with jwt if user is logged in and request is to api url
    const userData = this.localStorageService.getCurrentUser();
    const isApiUrl = request.url.startsWith(environment.baseApiUrl + 'user/');

    if (userData && userData.token && isApiUrl) {
      request = request.clone({
        headers: request.headers.set('Authorization', `Bearer ${userData.token}`),
      });
    }

    return next.handle(request);
  }
}
