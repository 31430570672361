import { Injectable } from '@angular/core';

import { AuthenticationService } from '@app/core/services';
import { Observable } from 'rxjs';
import { User } from '@app/core/types/user';

@Injectable({
  providedIn: 'root',
})
export class AppInitializerService {
  constructor(private authService: AuthenticationService) {}

  initializeApp(): Observable<User | null> {
    return this.authService.refreshLoggedInUser();
  }
}
