import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { User } from '@app/core/types/user';
import { ApiService } from '@app/core/services/api.service';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CurrentUserService {
  private loggedIn = new BehaviorSubject<boolean>(false);
  private user = new BehaviorSubject<User>({});
  private _firstLogin: boolean = false;

  constructor(private apiService: ApiService) {}

  currentUser(): Observable<User> {
    return this.user.asObservable();
  }

  getUser(): User {
    return this.user.value;
  }

  refreshUserBadgeInfo(): Observable<User> {
    return this.apiService.getUserBadgeInfo().pipe(
      map((badgeInfo) => ({
        ...this.user.value,
        userSettings: badgeInfo,
      })),
      tap((user) => this.updateUser(user)),
    );
  }

  getFirstLogin(): boolean {
    return this._firstLogin;
  }

  setFirstLogin(value: boolean) {
    this._firstLogin = value;
  }

  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  setLoggedIn(loggedIn: boolean) {
    this.loggedIn.next(loggedIn);
  }

  remove() {
    this.user.next(null!);
    this.setLoggedIn(false);
  }

  updateUser(user: User) {
    this.user.next({ ...this.user.value, ...user });
  }

  setUser(user: User) {
    this.user.next({ ...user });
  }
}
