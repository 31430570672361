<div class="navbar-top" [ngClass]="fuseConfig.layout.navbar.secondaryBackground">
  <div class="logo">
    <img
      [fxHide]="!navbar.opened || navbar.folded || menuMode"
      (click)="closeIfMenuMode()"
      class="logo-name"
      src="assets/images/logos/logo_for_dark_background.png" />
    <mat-icon
      [fxHide]="!navbar.opened || navbar.folded || !menuMode"
      (click)="closeIfMenuMode()"
      class="logo-name"
      src="assets/images/logos/tui.png">
      menu
    </mat-icon>
    <img
      [fxShow]="!navbar.opened || navbar.folded"
      class="logo-icon"
      src="assets/images/logos/favicon32White.png" />
  </div>
</div>

<div
  class="navbar-scroll-container"
  [ngClass]="fuseConfig.layout.navbar.primaryBackground"
  fusePerfectScrollbar
  [fusePerfectScrollbarOptions]="{ suppressScrollX: true }">
  <div class="navbar-content">
    <fuse-navigation class="material2" layout="vertical"></fuse-navigation>
  </div>
</div>
<app-version *ngIf="navbar.opened && !navbar.folded" class="app-version"></app-version>
