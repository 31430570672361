// @ts-strict-ignore
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DEFAULT_PAGINATION, Pagination } from '@app/core/types/common';
import { TranslateService } from '@ngx-translate/core';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent {
  @Input() pageIndex: number;
  @Input() set pageSize(value: number) {
    this._pageSize = value;
    this.pageSizeValue.setValue(value);
  }
  get pageSize(): number {
    return this._pageSize;
  }
  @Input() pageSizeOptions: number[];
  @Input() length = -1;

  @Input() isPageChangeAllowed: () => boolean;
  @Input() showNoResults = true;
  @Output() paginationChanged = new EventEmitter<Pagination>();

  pageSizeValue = new FormControl<number>(DEFAULT_PAGINATION.pageSize);

  private _pageSize: number;

  constructor(private translateService: TranslateService) {}

  get hasPreviousPage(): boolean {
    if (this.pageIndex === undefined) return false;
    return this.pageIndex > 0;
  }

  get hasNextPage(): boolean {
    if (!this.pageRange) return false;
    return this.pageRange.end < this.length;
  }

  get pageRange(): { start: number; end: number } | undefined {
    if (this.pageSize === undefined || this.pageIndex === undefined) return undefined;
    const start = this.pageSize * this.pageIndex + 1;
    const end = start - 1 + this.pageSize <= this.length ? start - 1 + this.pageSize : this.length;
    return { start, end };
  }

  get pageRangeLabel(): string {
    if (!this.pageRange) return '';
    return this.translateService.instant('pagination.rangeLabel', {
      ...this.pageRange,
      total: this.length,
    });
  }

  checkIsPageChangeAllowed(): boolean {
    if (!this.isPageChangeAllowed) return true;
    return this.isPageChangeAllowed();
  }

  onClickNextPage(): void {
    if (!this.hasNextPage || !this.checkIsPageChangeAllowed()) return;
    this.paginationChanged.emit({ pageIndex: this.pageIndex + 1, pageSize: this.pageSize });
  }

  onClickPrevPage(): void {
    if (!this.hasPreviousPage || !this.checkIsPageChangeAllowed()) return;
    this.paginationChanged.emit({ pageIndex: this.pageIndex - 1, pageSize: this.pageSize });
  }

  onChangePageSize({ value }: MatSelectChange): void {
    if (!this.checkIsPageChangeAllowed()) {
      // Mat select does not allow cancelling selection
      // Revert formControl value if change not allowed
      this.pageSizeValue.setValue(this.pageSize);
      return;
    }
    this.paginationChanged.emit({
      pageIndex: this.calculatePageIndex(this.pageIndex, this.pageSize, value),
      pageSize: value,
    });
  }

  private calculatePageIndex(
    previousPageIndex: number,
    previousPageSize: number,
    pageSize: number,
  ): number {
    // Calculate new page index after page size changed
    // Select page where current first item is visible
    const firstItemIndex = previousPageSize * previousPageIndex;
    return Math.floor(firstItemIndex / pageSize) || 0;
  }
}
