<div fxLayout="column" fusePerfectScrollbar>
  <mat-toolbar class="mat-toolbar mat-accent">
    <h2 mat-dialog-title>{{ 'welcome.title' | translate }}</h2>
  </mat-toolbar>
  <mat-dialog-content *ngIf="user.airline">
    <p>
      <span innerHtml="{{ 'welcome.welcomeAirline1' | translate }}"></span>
    </p>
    <p>
      <span innerHtml="{{ 'welcome.welcomeAirline2' | translate }}"></span>
    </p>
    <p>
      <span innerHtml="{{ 'welcome.welcomeAirline3' | translate }}"></span>
    </p>
    <p>
      <span innerHtml="{{ 'welcome.welcomeAirline4' | translate }}"></span>
    </p>
    <p>
      <span innerHtml="{{ 'welcome.welcomeAirline5' | translate }}"></span>
    </p>
    <p>
      <span innerHtml="{{ 'welcome.welcomeAirline6' | translate }}"></span>
    </p>
    <p>
      <span innerHtml="{{ 'welcome.welcomeAirline7' | translate }}"></span>
    </p>
  </mat-dialog-content>

  <mat-dialog-content *ngIf="user.client">
    <p>
      <span innerHtml="{{ 'welcome.welcomeBookingClient1' | translate }}"></span>
    </p>
    <p>
      <span innerHtml="{{ 'welcome.welcomeBookingClient2' | translate }}"></span>
    </p>
    <p>
      <span innerHtml="{{ 'welcome.welcomeBookingClient3' | translate }}"></span>
    </p>
    <p>
      <span innerHtml="{{ 'welcome.welcomeBookingClient4' | translate }}"></span>
    </p>
    <p>
      <span innerHtml="{{ 'welcome.welcomeBookingClient5' | translate }}"></span>
    </p>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-raised-button color="accent" (click)="onClose()" fxAlignLayout="center">
      {{ 'welcome.continue.button' | translate }}
    </button>
  </mat-dialog-actions>
</div>
