// @ts-strict-ignore
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService, CurrentUserService } from '@app/core/services';
import moment from 'moment';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private currentUserService: CurrentUserService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) {
    const expectedRoles: string = route.data.expectedRoles;
    const user = this.currentUserService.getUser();

    if (this.authenticationService.isAuthenticated()) {
      if (!expectedRoles || (user.role && expectedRoles.includes(user.role))) {
        //-- update user activity here if it hasn't been updated in an hour
        const lastActivityUpdate = user.lastActivityUpdatedAt
          ? moment(user.lastActivityUpdatedAt)
          : undefined;
        if (lastActivityUpdate?.isBefore(moment().subtract(1, 'hour'))) {
          this.authenticationService.updateUserActivity();
        }
        return true;
      }
    } else {
      if (location.href.indexOf('external') < 0) {
        /** If we are not on external pages, forward to login **/
        console.log('Forwarding to login from request to %s', location.href);
        //-- do not add returnUrl if the user is going to dashboard page by design
        let returnUrl = {};
        if (!_state.url.includes('/dashboard')) {
          returnUrl = {
            returnUrl: location.pathname,
          };
        }
        this.router.navigate(['./auth/login', returnUrl]);
      }
      return false;
    }
  }
}
