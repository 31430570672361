import { Injectable } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, first, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

export const GOOGLE_MAP_URL = `https://maps.googleapis.com/maps/api/js?key=${environment.mapsApiKey}`;
declare var google: any;

@Injectable({
  providedIn: 'root',
})
export class GoogleMapService {
  apiLoaded: BehaviorSubject<boolean>;

  constructor(private httpClient: HttpClient) {
    this.apiLoaded = new BehaviorSubject(false);
    this.loadGoogleMapsApi();
  }

  isGoogleMapsApiLoaded(): boolean {
    return typeof google === 'object' && typeof google.maps === 'object';
  }

  loadGoogleMapsApi(mapUrl: string = GOOGLE_MAP_URL) {
    if (this.isGoogleMapsApiLoaded()) {
      this.apiLoaded.next(true);
      return;
    }

    this.httpClient
      .jsonp(mapUrl, 'callback')
      .pipe(
        map(() => true),
        catchError(() => of(false)),
        first((value) => !!value),
      )
      .subscribe(() => this.apiLoaded.next(true));
  }
}
