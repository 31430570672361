import { NgModule } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';

import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';

@NgModule({
  declarations: [FuseConfirmDialogComponent],
  imports: [MatToolbarModule, MatIconModule],
})
export class FuseConfirmDialogModule {}
