/**
 * Use to format object to query param array, query params will be uri encoded
 * NOTE: If you have value as array or string with commas 'value1,value2,value3
 * These will be changed to separated by pipe value1|value2|value3
 * @param {object} params - for example { param1: value1, param2: value2 }
 * @returns {string} - for example param1=value1&param2=value2
 */
export const objectToQueryParams = (params: Record<string, any>): string => {
  const queryParamArr = Object.keys(params).reduce((arr: unknown[], paramKey) => {
    if (params[paramKey] === undefined) return arr;
    if (Array.isArray(params[paramKey])) {
      return [...arr, arrayToQueryParams(paramKey, params[paramKey])];
    }
    return [...arr, getSingleQueryParam(paramKey, getParamValue(params[paramKey]))];
  }, []);
  return queryParamArr.join('&');
};

const getParamValue = (param: any): string => {
  return param.toString().replace(/,/g, '|');
};

const getSingleQueryParam = (key: string, value: string): string => {
  return `${key}=${encodeSpecialCharacters(encodeURIComponent(value))}`;
};

/**
 * encodeURIComponent does not encode characters !*()
 * But Swagger wants these to be encoded, so encode these manually
 * @param value string to be encoded
 */
const encodeSpecialCharacters = (value: string): string => {
  return value.replace('*', '%2A').replace('(', '%28').replace(')', '%29').replace('!', '%21');
};

/**
 * Format array to query string
 * Query param values will be uri encoded
 * @param {string} queryParamName - name of the query param key
 * @param {array} data - array of the data to be set query values
 * @returns {string} - for example queryParamName=value1&queryParamName=value2&...
 */
export const arrayToQueryParams = (queryParamName: string, data: any[]): string => {
  if (data.length === 0) return '';
  return data.map((value) => getSingleQueryParam(queryParamName, value)).join('&');
};
