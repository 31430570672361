import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable()
export class AppSettingsConfig {
  googleReCaptchaV3Key = '6LdfhvMZAAAAAC1U7uj7lVELDEu84jAfATviSOXX';
  googleMapsApiKey = environment.mapsApiKey;
  supportedLanguages: Array<string> = ['fi-FI', 'en-US', 'sv-SE'];
  defaultLocale = 'en-US';
  countries = [
    {
      name: 'Estonia',
      code: 'EE',
    },
    {
      name: 'Finland',
      code: 'FI',
    },
    {
      name: 'Sweden',
      code: 'SE',
    },
    {
      name: 'Norway',
      code: 'NO',
    },
    {
      name: 'Denmark',
      code: 'DK',
    },
    {
      name: 'Spain',
      code: 'ES',
    },
    {
      name: 'USA',
      code: 'US',
    },
    {
      name: 'England',
      code: 'EN',
    },
  ];
  locales = [
    { title: 'Suomi', id: 'fi-FI', flag: 'fi' },
    { title: 'English', id: 'en-US', flag: 'us' },
    { title: 'Svenska', id: 'sv-SE', flag: 'se' },
  ];
  roles = ['root-admin', 'airline-admin', 'airline-user', 'client-admin', 'client-user'];

  serviceName = 'myPlane';
}
