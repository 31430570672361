import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';

import { catchError } from 'rxjs/operators';
import { AuthenticationService } from '@app/core/services';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (this.authenticationService.isAuthenticated()) {
          if (err.status === 401 || err.status === 403) {
            console.error('Invalid credentials!', err);
            this.authenticationService.signOut();
          }
        }

        const error = err.error || err.statusText;
        return throwError(error);
      }),
    );
  }
}
