// @ts-strict-ignore
import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from '@app/core/services';
import { DomSanitizer } from '@angular/platform-browser';
import { FileDownloadFileType } from '@app/shared/components/attachment-list/attachment-list.component';

@Component({
  selector: 'app-file-viewer',
  templateUrl: './file-viewer.component.html',
  styleUrls: ['./file-viewer.component.scss'],
})
export class FileViewerComponent implements OnInit {
  @Input() type: string | undefined = undefined;
  @Input() showButtons: boolean = true;
  @Input() authId: string | undefined = undefined;
  @Input() fileId: string | undefined = undefined;
  @Input() typePrivateFile: FileDownloadFileType | undefined = undefined;
  @Input() autoResize: boolean = true;
  @Input() originalSize: boolean = false;
  @Input() dialog: boolean = false;
  pdfZoom = 1;

  src: any;
  previewType = 'fileLink';

  constructor(
    private apiService: ApiService,
    private domSanitizer: DomSanitizer,
  ) {}

  ngOnInit(): void {
    if (!this.type) {
      this.type = this.fileId?.substring(this.fileId.lastIndexOf('.') + 1).toLowerCase();
    }
    if (this.type == 'png' || this.type == 'gif' || this.type == 'jpg' || this.type == 'jpeg') {
      this.previewType = 'image';
    }
    if (this.type == 'xls' || this.type == 'xlsx') {
      this.previewType = 'spreadsheet';
    }
    if (this.type == 'doc' || this.type == 'docx') {
      this.previewType = 'document';
    }
    if (this.type == 'pdf') {
      this.previewType = 'pdf';
      this.pdfZoom = this.dialog ? 1 : 0.1;
    }

    if (this.authId && this.fileId && this.previewType == 'image') {
      this.getPrivateFile(this.authId, this.fileId);
    }
  }

  zoomIn(): void {
    this.pdfZoom += 0.25;
  }

  zoomOut(): void {
    this.pdfZoom -= 0.25;
  }

  getPrivateFile(authId: string, fileId: string) {
    switch (this.typePrivateFile) {
      case FileDownloadFileType.MESSAGES:
        if (fileId.includes('.pdf')) {
          this.apiService.downloadMessageFile(authId, fileId).subscribe((fileUrl) => {
            const blob = new Blob([fileUrl], { type: 'octet/stream' });
            this.src = window.URL.createObjectURL(blob);
          });
        } else {
          this.apiService.downloadMessageFile(authId, fileId).subscribe(
            (buffer) => {
              let src = this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(buffer));
              this.src = src;
            },
            (error) => {
              console.error(error);
            },
          );
        }
        break;

      case FileDownloadFileType.INVOICES:
        this.showButtons = false;
        if (fileId.includes('.pdf')) {
          this.apiService.downloadInvoiceFile(authId, fileId).subscribe((fileUrl) => {
            const blob = new Blob([fileUrl], { type: 'application/octet-stream' });
            this.src = window.URL.createObjectURL(blob);
          });
        } else {
          this.apiService.downloadInvoiceFile(authId, fileId).subscribe((buffer) => {
            let src = this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(buffer));
            this.src = src;
          });
        }
        break;

      case FileDownloadFileType.AIRLINE_ATTACHMENT:
        this.showButtons = false;
        if (fileId.includes('.pdf')) {
          this.apiService.downloadAirlineAttachment(authId, fileId).subscribe((fileUrl) => {
            const blob = new Blob([fileUrl], { type: 'application/octet-stream' });
            this.src = window.URL.createObjectURL(blob);
          });
        } else {
          this.apiService.downloadAirlineAttachment(authId, fileId).subscribe((buffer) => {
            let src = this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(buffer));
            this.src = src;
          });
        }
        break;

      case FileDownloadFileType.PAX_LIST_TEMPLATE:
        this.showButtons = false;
        if (fileId.includes('.pdf')) {
          this.apiService.downloadAirlinePaxListTemplate(authId, fileId).subscribe((fileUrl) => {
            const blob = new Blob([fileUrl], { type: 'application/octet-stream' });
            this.src = window.URL.createObjectURL(blob);
          });
        } else {
          this.apiService.downloadAirlinePaxListTemplate(authId, fileId).subscribe((buffer) => {
            let src = this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(buffer));
            this.src = src;
          });
        }
        break;
      case FileDownloadFileType.CONTRACT_TERMS:
        //this.showButtons = false;
        if (fileId.includes('.pdf')) {
          this.apiService.downloadContractTerms(authId, fileId).subscribe((fileUrl) => {
            const blob = new Blob([fileUrl], { type: 'application/octet-stream' });
            const url = window.URL.createObjectURL(blob);
            this.src = url;
          });
        } else {
          this.apiService.downloadContractTerms(authId, fileId).subscribe((buffer) => {
            let src = this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(buffer));
            this.src = src;
          });
        }
        break;

      case FileDownloadFileType.ATTACHMENT:
        //this.showButtons = false;
        if (fileId.includes('.pdf')) {
          this.apiService.downloadContractTerms(authId, fileId).subscribe((fileUrl) => {
            const blob = new Blob([fileUrl], { type: 'application/octet-stream' });
            const url = window.URL.createObjectURL(blob);
            this.src = url;
          });
        } else {
          this.apiService.downloadAttachment(authId, fileId).subscribe((buffer) => {
            let src = this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(buffer));
            this.src = src;
          });
        }
        break;

      case FileDownloadFileType.PAX_LIST:
        this.showButtons = false;
        if (fileId.includes('.pdf')) {
          this.apiService.downloadPaxList(authId, fileId).subscribe((fileUrl) => {
            const blob = new Blob([fileUrl], { type: 'application/octet-stream' });
            this.src = window.URL.createObjectURL(blob);
          });
        } else {
          this.apiService.downloadPaxList(authId, fileId).subscribe((buffer) => {
            let src = this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(buffer));
            this.src = src;
          });
        }
        break;
      default:
        console.warn('CANNOT DISPLAY TYPE:', this.typePrivateFile);
        break;
    }
  }
}
